














































































































































































.el-textarea__inner {
  resize: none;
}
